import React from "react";
import Icon from "@ant-design/icons";

const CkReturnCurveSvg = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_758_29513)">
      <path
        d="M1.25015 12.33C1.66015 12.33 2.00015 12 2.00015 11.58V8.43997C2.51015 10.01 3.59015 11.39 5.10015 12.41C7.30015 13.88 10.1202 14.41 12.8502 13.85C16.8402 13.03 19.9002 10.08 20.4402 6.50997C20.5002 6.09997 20.2202 5.71997 19.8102 5.65997C19.7702 5.65997 19.7302 5.65997 19.7002 5.65997C19.3402 5.65997 19.0202 5.91997 18.9602 6.28997C18.5202 9.19997 15.8702 11.71 12.5302 12.4C10.2002 12.88 7.78015 12.43 5.91015 11.18C4.46015 10.21 3.51015 8.83997 3.22015 7.31997C3.22015 7.28997 3.20015 7.26997 3.19015 7.23997H6.14015C6.55015 7.23997 6.89015 6.90997 6.89015 6.48997C6.89015 6.06997 6.56015 5.73997 6.14015 5.73997H1.24015C0.830151 5.73997 0.49015 6.06997 0.49015 6.48997V11.59C0.49015 12 0.82015 12.34 1.24015 12.34L1.25015 12.33Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

const CkReturnCurveIcon = (props: any) => (
  <Icon component={CkReturnCurveSvg} {...props} />
);

export default CkReturnCurveIcon;

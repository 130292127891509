import React from "react";
import Icon from "@ant-design/icons";

const CkPaperClipSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9998 0.68328C20.0001 0.671057 20.0001 0.65928 19.9998 0.647057C19.9989 0.617279 19.9961 0.587946 19.9914 0.558613C19.9898 0.549502 19.9894 0.540613 19.9874 0.531501C19.9798 0.494835 19.9692 0.458835 19.9554 0.423946C19.9514 0.413723 19.9463 0.404168 19.9418 0.394168C19.9301 0.367723 19.9167 0.342168 19.9014 0.317279C19.8952 0.307056 19.8889 0.296834 19.8821 0.286612C19.8594 0.253945 19.8349 0.222612 19.8063 0.193945C19.7774 0.165056 19.7456 0.140389 19.7127 0.1175C19.7034 0.111056 19.6938 0.105056 19.6841 0.0992781C19.6578 0.0830558 19.6309 0.0690558 19.6032 0.0568335C19.5945 0.0530557 19.5863 0.0486112 19.5774 0.0450557C19.5416 0.0310556 19.5047 0.0199445 19.4669 0.0123889C19.4601 0.0110556 19.4529 0.0106111 19.4461 0.0092778C19.4145 0.00394446 19.3827 0.000833336 19.3507 0.000166667C19.3401 -5.55557e-05 19.3296 -5.55557e-05 19.3192 0.000166667C19.2878 0.000833336 19.2565 0.00394446 19.2252 0.0092778C19.2174 0.0106111 19.2098 0.0110556 19.2021 0.0126111C19.1678 0.0195001 19.1341 0.0288334 19.1007 0.0412779L0.432457 7.04641C0.182679 7.14019 0.0129009 7.37374 0.000678619 7.64041C-0.0113214 7.90686 0.136679 8.15508 0.376902 8.27108L8.03359 11.9662L11.7285 19.6229C11.8403 19.8542 12.0743 20 12.3292 20C12.3394 20 12.3494 19.9998 12.3596 19.9993C12.626 19.9871 12.8598 19.8173 12.9536 19.5676L19.9589 0.899503C19.9714 0.866391 19.9805 0.832836 19.9874 0.798836C19.9892 0.789724 19.9898 0.780836 19.9912 0.771724C19.9961 0.742391 19.9989 0.712835 19.9998 0.68328ZM16.6858 2.37151L8.3967 10.6606L2.35979 7.74752L16.6858 2.37151ZM12.2525 17.6402L9.33937 11.6035L17.6287 3.31418L12.2525 17.6402Z" fill="currentColor"/>
  </svg>
);

const CkPaperClipIcon = (props: any) => (
  <Icon component={CkPaperClipSvg} {...props} />
);

export default CkPaperClipIcon;

import React from "react";
import Icon from "@ant-design/icons";

const CkPaperClipSvg = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 10.6903C21 10.6903 17.9968 13.5075 11.9904 19.1419C5.984 24.7763 -3.02559 16.3247 2.9808 10.6903C8.9872 5.05594 7.98614 5.995 11.9904 2.23875C15.9947 -1.51751 22.0011 4.11687 17.9968 7.87312C13.9925 11.6294 10.9893 14.4466 8.9872 16.3247C6.98507 18.2028 3.98187 15.3856 5.984 13.5075C7.98614 11.6294 14.9936 5.05593 14.9936 5.05593" stroke="currentColor" stroke-width="2"/>
  </svg>
);

const CkPaperClipIcon = (props: any) => (
  <Icon component={CkPaperClipSvg} {...props} />
);

export default CkPaperClipIcon;

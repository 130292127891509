import React from "react";
import Icon from "@ant-design/icons";

const CkReloadSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_758_29493)">
      <path
        d="M18.4998 9.3C17.9998 9.3 17.6998 9.7 17.6998 10.1C17.6998 14.3 14.2998 17.8 9.9998 17.8C5.7998 17.8 2.2998 14.4 2.2998 10.1C2.2998 5.9 5.6998 2.4 9.9998 2.4C12.1998 2.4 14.2998 3.4 15.8998 5.1H13.5998C13.0998 5.1 12.7998 5.5 12.7998 5.9C12.7998 6.4 13.1998 6.7 13.5998 6.7H17.9998C18.4998 6.7 18.7998 6.3 18.7998 5.9V1.3C18.7998 0.8 18.3998 0.5 17.9998 0.5C17.5998 0.5 17.1998 0.9 17.1998 1.3V4.1C15.3998 2 12.7998 0.8 10.0998 0.8C4.9998 0.8 0.799805 5 0.799805 10.1C0.799805 15.2 4.9998 19.4 10.0998 19.4C15.1998 19.4 19.3998 15.2 19.3998 10.1C19.2998 9.7 18.9998 9.3 18.4998 9.3Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

const CkReloadIcon = (props: any) => (
  <Icon component={CkReloadSvg} {...props} />
);

export default CkReloadIcon;

import React from "react";
import Icon from "@ant-design/icons";

export const CkUserSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.2997 28.94H4.71973C4.58712 28.94 4.45995 28.8873 4.36618 28.7936C4.27241 28.6998 4.21973 28.5726 4.21973 28.44V22.91C4.21862 22.1927 4.46006 21.4961 4.90483 20.9333C5.3496 20.3705 5.97158 19.9747 6.66973 19.81L13.9997 18.3C14.0728 18.2863 14.148 18.2881 14.2204 18.3053C14.2927 18.3225 14.3607 18.3548 14.4197 18.4C14.4753 18.4489 14.52 18.5088 14.551 18.576C14.582 18.6431 14.5986 18.716 14.5997 18.79C14.5996 18.9523 14.6322 19.113 14.6955 19.2625C14.7588 19.412 14.8515 19.5472 14.9682 19.6601C15.0848 19.773 15.2229 19.8612 15.3744 19.9196C15.5258 19.978 15.6875 20.0054 15.8497 20H16.1597C16.4816 20 16.7904 19.8729 17.0189 19.6462C17.2474 19.4196 17.3771 19.1118 17.3797 18.79C17.3789 18.7157 17.3946 18.6422 17.4257 18.5747C17.4569 18.5072 17.5026 18.4476 17.5597 18.4C17.6188 18.3548 17.6867 18.3225 17.7591 18.3053C17.8314 18.2881 17.9066 18.2863 17.9797 18.3L25.3397 19.81C26.0397 19.9727 26.664 20.3678 27.1107 20.9307C27.5574 21.4937 27.8003 22.1913 27.7997 22.91V28.44C27.7972 28.5718 27.7437 28.6975 27.6505 28.7907C27.5572 28.884 27.4315 28.9375 27.2997 28.94ZM5.21973 27.94H26.7997V22.94C26.8016 22.4474 26.6365 21.9687 26.3314 21.5819C26.0263 21.1951 25.5992 20.923 25.1197 20.81L18.2997 19.39C18.1655 19.8539 17.8842 20.2617 17.4983 20.552C17.1124 20.8423 16.6427 20.9996 16.1597 21H15.8497C15.3677 21.0007 14.8987 20.8437 14.5141 20.553C14.1296 20.2624 13.8506 19.8539 13.7197 19.39L6.87973 20.79C6.40475 20.9049 5.98243 21.1767 5.68116 21.5614C5.37989 21.9462 5.2173 22.4213 5.21973 22.91V27.94Z"
      fill="currentColor"
    ></path>
    <path
      d="M15.9999 21C15.6886 21 15.3805 20.9387 15.0929 20.8196C14.8054 20.7005 14.5441 20.5259 14.324 20.3059C14.104 20.0858 13.9294 19.8245 13.8103 19.537C13.6912 19.2494 13.6299 18.9412 13.6299 18.63V16.63C13.631 16.5501 13.6506 16.4716 13.687 16.4005C13.7234 16.3294 13.7757 16.2676 13.8399 16.22C13.9047 16.175 13.9788 16.1453 14.0568 16.1331C14.1348 16.121 14.2145 16.1268 14.2899 16.15C14.801 16.3285 15.3385 16.4198 15.8799 16.42H16.1199C16.6612 16.419 17.1986 16.3278 17.7099 16.15C17.7853 16.1268 17.865 16.121 17.943 16.1331C18.0209 16.1453 18.0951 16.175 18.1599 16.22C18.224 16.2676 18.2763 16.3294 18.3128 16.4005C18.3492 16.4716 18.3687 16.5501 18.3699 16.63V18.63C18.3673 19.2578 18.1167 19.8591 17.6728 20.3029C17.2289 20.7468 16.6276 20.9974 15.9999 21ZM14.6299 17.27V18.63C14.6299 18.9934 14.7742 19.3418 15.0311 19.5988C15.2881 19.8557 15.6365 20 15.9999 20C16.3632 20 16.7117 19.8557 16.9686 19.5988C17.2255 19.3418 17.3699 18.9934 17.3699 18.63V17.27C16.9594 17.361 16.5403 17.4079 16.1199 17.41H15.8799C15.4627 17.4078 15.047 17.3608 14.6399 17.27H14.6299Z"
      fill="currentColor"
    ></path>
    <path
      d="M16.1296 17.41H15.8896C14.2983 17.41 12.7722 16.7779 11.647 15.6526C10.5218 14.5274 9.88965 13.0013 9.88965 11.41V9C9.88965 7.4087 10.5218 5.88258 11.647 4.75736C12.7722 3.63214 14.2983 3 15.8896 3H16.1296C17.7209 3 19.2471 3.63214 20.3723 4.75736C21.4975 5.88258 22.1296 7.4087 22.1296 9V11.43C22.1244 13.0178 21.4899 14.5388 20.3652 15.6597C19.2406 16.7806 17.7175 17.41 16.1296 17.41ZM15.8896 4.06C14.5636 4.06 13.2918 4.58678 12.3541 5.52447C11.4164 6.46215 10.8896 7.73392 10.8896 9.06V11.49C10.8896 12.1466 11.019 12.7968 11.2703 13.4034C11.5215 14.01 11.8898 14.5612 12.3541 15.0255C13.2918 15.9632 14.5636 16.49 15.8896 16.49H16.1296C16.7863 16.49 17.4364 16.3607 18.0431 16.1094C18.6497 15.8581 19.2009 15.4898 19.6652 15.0255C20.1295 14.5612 20.4978 14.01 20.749 13.4034C21.0003 12.7968 21.1296 12.1466 21.1296 11.49V9C21.1296 7.67392 20.6029 6.40215 19.6652 5.46447C18.7275 4.52678 17.4557 4 16.1296 4L15.8896 4.06Z"
      fill="currentColor"
    ></path>
    <path
      d="M9.71973 28.94C9.58712 28.94 9.45994 28.8873 9.36617 28.7936C9.2724 28.6998 9.21973 28.5726 9.21973 28.44V25.54C9.21973 25.4074 9.2724 25.2802 9.36617 25.1865C9.45994 25.0927 9.58712 25.04 9.71973 25.04C9.85233 25.04 9.97951 25.0927 10.0733 25.1865C10.167 25.2802 10.2197 25.4074 10.2197 25.54V28.44C10.2197 28.5726 10.167 28.6998 10.0733 28.7936C9.97951 28.8873 9.85233 28.94 9.71973 28.94Z"
      fill="currentColor"
    ></path>
    <path
      d="M22.2998 28.94C22.1672 28.94 22.04 28.8873 21.9463 28.7936C21.8525 28.6998 21.7998 28.5726 21.7998 28.44V25.54C21.7998 25.4074 21.8525 25.2802 21.9463 25.1865C22.04 25.0927 22.1672 25.04 22.2998 25.04C22.4324 25.04 22.5596 25.0927 22.6534 25.1865C22.7471 25.2802 22.7998 25.4074 22.7998 25.54V28.44C22.7972 28.5718 22.7437 28.6975 22.6505 28.7907C22.5573 28.8839 22.4316 28.9374 22.2998 28.94Z"
      fill="currentColor"
    ></path>
  </svg>
);

const CkUserIcon = (props: any) => <Icon component={CkUserSvg} {...props} />;

export default CkUserIcon;
